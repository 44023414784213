import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'

export const SubcategoryGrid = ({ children }) => (
  <ul className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 my-8">{children}</ul>
)

export const SubcategoryCardContainer = ({ title, children }) => (
  <li className="block no-underline border-t-4 rounded-b-lg border-primary space-y-2 bg-white row-span-2 pb-3 list-none">
    <div className="text-lg tracking-tight font-bold mb-4 m-4">{title}</div>
    {children}
  </li>
)

export const SubcategoryCard = ({
  url = null,
  title,
  subtitle = null,
  coverImage = null,
}) => (
  <li
    className={clsx(
      'sm:max-w-xs  bg-white hover:shadow-lg list-none cursor-pointer group',
      coverImage ? 'rounded-lg' : 'border-t-4 border-primary rounded-b-lg'
    )}
  >
    <Link
      href={url || '#'}
      className={clsx(
        'rounded-b-lg block no-underline space-y-2 min-h-[120px]',
        coverImage || 'p-4'
      )}
    >
      <>
        {coverImage && (
          <div className="relative h-44">
            <Image
              alt="cover"
              className="rounded-t-lg object-cover"
              src={`https://${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_DOMAIN}/${coverImage.id}`}
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            />
          </div>
        )}
        <div className={clsx(coverImage && 'p-4')}>
          <b className="text-xl text-primary group-hover:underline tracking-tight">
            {title}
          </b>
          {subtitle && <p className="text-black mt-1 mb-0">{subtitle}</p>}
        </div>
      </>
    </Link>
  </li>
)
