import { Icon } from '@/design-system'
import Link from 'next/link'

export const SidebarBox = ({
  title,
  description,
  items,
}: {
  title: string
  description?: string
  items: Array<any>
}) => (
  <div className="flex flex-col p-4 mb-8 border-t-4 md:max-h-screen md:overflow-y-auto bg-slate-100 border-primary rounded-b-lg">
    <h2 className="mb-2 text-lg font-bold">{title}</h2>
    {items?.map((item, idx) => (
      <div className="mb-2">
        <Link href={item.url || ''} key={idx} className="flex items-start mt-2">
          <>
            {item.icon && (
              <Icon
                name={item.icon}
                className="min-w-[20px] w-5 h-5 mr-1.5 mt-0.5"
              />
            )}
            <div className="max-w-[270px] text-ellipsis overflow-hidden">
              {item.title}
            </div>
          </>
        </Link>
        <div className="ml-6">{item.description}</div>
      </div>
    ))}
  </div>
)
