import PageBreadcrumbs from '@/components/PageBreadcrumbs'
import { SubcategoryCard, SubcategoryGrid } from '@/components/SubcategoryCard'
import {
  Footer,
  Header,
  Icon,
  Main,
  Page,
  Section,
  Wrap,
} from '@/design-system'
import { MenuItems, Pages } from '@/generated/graphql'
import { sdk } from '@/lib/sdk'
import clsx from 'clsx'
import { SidebarBox } from 'design-system/components/SidebarBox'
import Link from 'next/link'
import ReactHtmlParser, {
  convertNodeToElement,
  processNodes,
} from 'react-html-parser'

export function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export async function getStaticProps({ params: { pageUrl }, preview = false }) {
  const { menu_items, pages } = await sdk().menuByUrl({
    url: `/${pageUrl.join('/')}`,
  })

  const page = pages[0] || null

  return {
    notFound: page == null,
    props: { page: page, menu: menu_items, preview },
  }
}

const getHeader = (page: Pages) => (
  <>
    <PageBreadcrumbs page={page} />
    <Header.Title>{page.title}</Header.Title>
    {page.description && (
      <Header.Description>{page.description}</Header.Description>
    )}
  </>
)

export default function AnyPage({
  page,
  menu,
  preview,
}: {
  page: Pages
  menu: MenuItems[]
  preview: boolean
}) {
  let headings = []
  const handleHtmlNode = (node, index) => {
    if (page.options?.includes('toc') && node.name == 'h2') {
      console.log('handle node', node.name, page.options.includes('toc'))
      headings.push({ index, text: node.children[0].data })
      node.attribs.id = `h${index}`
      return convertNodeToElement(node, index, handleHtmlNode)
    }
    if (node.name == 'a') {
      return (
        <Link href={node.attribs.href} title={node.attribs.title}>
          {processNodes(node.children, handleHtmlNode)}
        </Link>
      )
    }
  }

  return (
    <Page preview={preview}>
      <Header menu={menu}>
        {page.page_type == 'section-home' && getHeader(page)}
      </Header>
      {page.page_type != 'section-home' && <Wrap>{getHeader(page)}</Wrap>}

      {page.child_pages?.length > 0 && (
        <Section dark>
          <SubcategoryGrid>
            {page.child_pages.map((childPage) => (
              <SubcategoryCard
                key={childPage.url}
                url={childPage.url || '#'}
                title={childPage.title}
                subtitle={childPage.description}
                coverImage={childPage.cover}
              />
            ))}
          </SubcategoryGrid>
        </Section>
      )}

      {page.featured_sections?.length > 0 &&
        page.featured_sections.map((section) => (
          <Section dark>
            {section.title && (
              <h2 className="border-none mt-0">{section.title}</h2>
            )}
            <SubcategoryGrid>
              {section.featured_items?.map((childPage) => (
                <SubcategoryCard
                  key={childPage.url}
                  url={childPage.url || '#'}
                  title={childPage.title}
                  subtitle={childPage.description}
                />
              ))}
            </SubcategoryGrid>
          </Section>
        ))}

      <Main>
        <div
          className={clsx(
            (page.sidebar || page.options?.includes('toc')) &&
              'md:grid gap-x-8 grid-cols-2-1'
          )}
        >
          <section className="rendered-block">
            {ReactHtmlParser(page.content, { transform: handleHtmlNode })}
          </section>

          {(page.sidebar || page.options?.includes('toc')) && (
            <aside className="mt-16 md:mt-0">
              {page.sidebar?.map((section, idx) => (
                <SidebarBox title={section.title} items={section.links} />
              ))}

              {/* {page.options?.includes('toc') && <Toc blocks={page.content} />} */}
            </aside>
          )}
        </div>
      </Main>
      <Footer />
    </Page>
  )
}
