import { Pages } from '@/generated/graphql'
import Link from 'next/link'
import { Breadcrumbs } from '@/design-system'

const PageBreadcrumbs = ({ page }: { page: Pages }) => (
  <Breadcrumbs>
    {process.env.NEXT_PUBLIC_INFOCENTRU || process.env.NEXT_PUBLIC_TINERI ? (
      <></>
    ) : (
      <Link href="/">Acasă</Link>
    )}
    {page.parent_page?.parent_page?.parent_page?.parent_page && (
      <Link href={page.parent_page.parent_page.parent_page.parent_page.url}>
        {page.parent_page.parent_page.parent_page.parent_page.title}
      </Link>
    )}
    {page.parent_page?.parent_page?.parent_page && (
      <Link href={page.parent_page.parent_page.parent_page.url}>
        {page.parent_page.parent_page.parent_page.title}
      </Link>
    )}
    {page.parent_page?.parent_page && (
      <Link href={page.parent_page.parent_page.url}>
        {page.parent_page.parent_page.title}
      </Link>
    )}
    {page.parent_page && (
      <Link href={page.parent_page.url}>{page.parent_page.title}</Link>
    )}
  </Breadcrumbs>
)

export default PageBreadcrumbs
